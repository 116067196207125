import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  TextField,
  CardActions,
  IconButton,
  Alert,
  Snackbar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Chip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CardGiftcard from '@mui/icons-material/CardGiftcard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import GiftList from '../gifts/GiftList';
import { useAuth } from '../../contexts/AuthContext';
import { recipientsAPI } from '../../services/api';
import axios, { AxiosResponse } from 'axios';
import { 
  DndContext, 
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  DragEndEvent 
} from '@dnd-kit/core';
import { 
  arrayMove, 
  SortableContext, 
  verticalListSortingStrategy,
  sortableKeyboardCoordinates 
} from '@dnd-kit/sortable';
import { useSensors, useSensor } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { useTheme } from '@mui/material/styles';
import { CSS } from '@dnd-kit/utilities';
import { 
  genderOptions, 
  occasionOptions, 
  relationshipOptions, 
  interestSuggestions 
} from './formOptions';

interface Recipient {
  id: number;
  name: string;
  gender?: string;
  age?: string;
  occasion?: string;
  budget?: string;
  description_interests?: string;
  relationship?: string;
  liked_gift_ideas?: string;
  disliked_gift_ideas?: string;
  previous_successful_gifts?: string;
  previous_unsuccessful_gifts?: string;
  is_active: boolean;
  order_index: number;
  suggestions?: any[];
}

interface RecipientResponse {
  success: boolean;
  data: Recipient;
  message?: string;
}

interface FormData {
  name: string;
  gender: string;
  age: string;
  occasion: string;
  budget: string;
  description_interests: string;
  relationship: string;
  liked_gift_ideas: string;
  disliked_gift_ideas: string;
  previous_successful_gifts: string;
  previous_unsuccessful_gifts: string;
}

interface SuggestionFormData {
  title: string;
  description: string;
  price: string;
  url: string;
}

// Sophisticated color configuration
const colorConfig = {
  light: {
    recipient: {
      background: 'rgba(230, 230, 250, 0.5)',  // Soft violet
      hover: 'rgba(230, 230, 250, 0.7)'
    },
    buttonSection: {
      background: 'rgba(230, 240, 255, 0.5)',   // Soft blue
      edit: '#5C6BC0',      // Indigo
      remove: '#EF5350',    // Red
      add: '#2E7D32',       // Darker Green
      ai: '#FF9800',        // Orange
      duplicate: '#3F51B5'  // Primary Indigo
    }
  },
  dark: {
    recipient: {
      background: 'rgba(50, 40, 80, 0.3)',     // Deep violet
      hover: 'rgba(50, 40, 80, 0.4)'
    },
    buttonSection: {
      background: 'rgba(30, 40, 80, 0.3)',     // Deep blue
      edit: '#7986CB',      // Lighter Indigo
      remove: '#EF5350',    // Red
      add: '#81C784',       // Lighter Green
      ai: '#FFB74D',        // Lighter Orange
      duplicate: '#5C6BC0'  // Lighter Primary Indigo
    }
  }
};

// Memoize the GiftList wrapper to prevent unnecessary re-renders
const MemoizedGiftList = React.memo(({ recipientId, isEditing, generatingSuggestions, onEmptySuggestions }: { 
  recipientId: number;
  isEditing: boolean;
  generatingSuggestions?: boolean;
  onEmptySuggestions?: () => void;
}) => {
  return <GiftList 
    recipientId={recipientId} 
    isEditing={isEditing} 
    generatingSuggestions={generatingSuggestions} 
    onEmptySuggestions={onEmptySuggestions}
  />;
});

const SortableRecipient = React.memo(({
  recipient, 
  onEdit, 
  onDelete,
  onAddSuggestion,
  onGenerateAI,
  onDuplicate,
  isEditing,
  generatingSuggestions,
  onEmptySuggestions
}: {
  recipient: Recipient, 
  onEdit: (recipient: Recipient) => void, 
  onDelete: (id: number) => void,
  onAddSuggestion: (recipient: Recipient) => void,
  onGenerateAI: (id: number) => void,
  onDuplicate: (recipient: Recipient) => void,
  isEditing: boolean,
  generatingSuggestions: boolean,
  onEmptySuggestions: () => void
}) => {
  const theme = useTheme();
  const colors = colorConfig[theme.palette.mode as 'light' | 'dark'];

  // Ensure we have a valid ID before calling toString()
  const sortableId = recipient?.id ? recipient.id.toString() : '';

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: sortableId });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  // Memoize handlers to prevent unnecessary re-renders
  const handleEdit = useCallback(() => onEdit(recipient), [onEdit, recipient]);
  const handleDelete = useCallback(() => onDelete(recipient.id), [onDelete, recipient.id]);
  const handleAddSuggestion = useCallback(() => onAddSuggestion(recipient), [onAddSuggestion, recipient]);
  const handleGenerateAI = useCallback(() => onGenerateAI(recipient.id), [onGenerateAI, recipient.id]);
  const handleDuplicate = useCallback(() => onDuplicate(recipient), [onDuplicate, recipient]);

  return (
    <Box ref={setNodeRef} style={style}>
      <Card sx={{ 
        mb: 2, 
        borderRadius: 2,
        backgroundColor: theme => theme.palette.mode === 'dark' 
          ? 'rgba(255, 255, 255, 0.05)'  // Greyish background for dark mode
          : 'rgba(240, 240, 240, 1)',  // Soft light grey background for light mode
        boxShadow: theme => theme.palette.mode === 'dark' 
          ? 'none' 
          : '0 4px 15px rgba(0,0,0,0.05), 0 1px 3px rgba(0,0,0,0.08)',  // Modern, subtle shadow
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
        position: 'relative',
        overflow: 'visible',
        width: '100%',
        border: theme => theme.palette.mode === 'light' 
          ? '1px solid rgba(0,0,0,0.05)' 
          : 'none',
        '&:hover': {
          backgroundColor: theme => theme.palette.mode === 'dark' 
            ? 'rgba(255, 255, 255, 0.08)' // Slightly lighter grey on hover
            : 'rgba(240, 240, 240, 0.95)',  // Slightly more opaque on hover
          boxShadow: theme => theme.palette.mode === 'dark' 
            ? 'none' 
            : '0 6px 20px rgba(0,0,0,0.08), 0 2px 4px rgba(0,0,0,0.1)'  // Slightly larger shadow on hover
        }
      }}>
        {/* Content Section */}
        <Box sx={{ 
          p: { xs: 1.5, sm: 2 },  // Less padding on mobile
          width: '100%'
        }}>
          {/* Header Section with Drag Handle */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'flex-start',
            mb: 1,
            position: 'relative'
          }}>
            {/* Drag Handle */}
            <Box 
              {...attributes} 
              {...listeners}
              sx={{
                position: 'absolute',
                left: -16,
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'grab',
                padding: '8px',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.05)' 
                    : 'rgba(0, 0, 0, 0.05)',
                },
                '&:active': {
                  cursor: 'grabbing',
                  backgroundColor: theme => theme.palette.mode === 'dark' 
                    ? 'rgba(255, 255, 255, 0.1)' 
                    : 'rgba(0, 0, 0, 0.1)',
                }
              }}
            >
              <DragIndicatorIcon sx={{ 
                opacity: 0.5,
                fontSize: '20px',
                transform: 'rotate(90deg)'
              }} />
            </Box>

            {/* Recipient Info */}
            <Box sx={{ flex: 1, ml: 3 }}>
              <Typography variant="h6" sx={{ 
                fontWeight: 600,
                color: theme => theme.palette.mode === 'dark' 
                  ? theme.palette.primary.light 
                  : theme.palette.primary.main
              }}>
                {recipient.occasion ? `${recipient.occasion} gift for ${recipient.name}` : recipient.name}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                {recipient.relationship}
                {recipient.age && ` • ${recipient.age}`}
                {recipient.gender && ` • ${recipient.gender}`}
              </Typography>
            </Box>
            
            {/* Action Buttons */}
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton 
                onClick={handleDuplicate}
                size="small"
                sx={{
                  color: colors.buttonSection.duplicate,
                  '&:hover': {
                    backgroundColor: `${colors.buttonSection.duplicate}10`
                  }
                }}
              >
                <ContentCopyIcon />
              </IconButton>
              <IconButton 
                onClick={handleEdit}
                size="small"
                sx={{
                  color: colors.buttonSection.edit,
                  '&:hover': {
                    backgroundColor: `${colors.buttonSection.edit}10`
                  }
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton 
                onClick={handleDelete}
                size="small"
                sx={{
                  color: colors.buttonSection.remove,
                  '&:hover': {
                    backgroundColor: `${colors.buttonSection.remove}10`
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>

          {/* Interests/Description Box */}
          {recipient.description_interests && (
            <Box 
              onClick={handleEdit}
              sx={{
                p: 2,
                mt: 2,
                borderRadius: 1,
                backgroundColor: theme => theme.palette.mode === 'dark' 
                  ? 'rgba(255,255,255,0.05)' 
                  : 'rgba(0,0,0,0.03)',
                cursor: 'pointer',
                transition: 'background-color 0.2s ease',
                '&:hover': {
                  backgroundColor: theme => theme.palette.mode === 'dark'
                    ? 'rgba(255,255,255,0.08)'
                    : 'rgba(0,0,0,0.05)',
                }
              }}>
              <Typography variant="body2" color="text.secondary" sx={{ 
                fontWeight: 500,
                mb: 0.5,
                textTransform: 'uppercase',
                fontSize: '0.75rem',
                letterSpacing: '0.1em'
              }}>
                About them
              </Typography>
              <Typography variant="body1">
                {recipient.description_interests}
              </Typography>
            </Box>
          )}

          {/* Button Section */}
          <Box sx={{
            display: 'flex',
            gap: { xs: 0.5, sm: 1 },  // Less gap on mobile
            justifyContent: 'flex-end',
            mt: 2,
            flexWrap: 'wrap'  // Allow buttons to wrap on very small screens
          }}>
            {/* <Button
              size="small"
              startIcon={<EditIcon />}
              onClick={handleEdit}
              sx={{ 
                backgroundColor: colors.buttonSection.edit,
                color: 'white',
                fontSize: { xs: '0.75rem', sm: '0.875rem' },  // Smaller font on mobile
                '&:hover': {
                  backgroundColor: colors.buttonSection.edit,
                  opacity: 0.9
                }
              }}
            >
              Edit
            </Button>
            <Button
              size="small"
              startIcon={<DeleteIcon />}
              onClick={handleDelete}
              sx={{ 
                backgroundColor: colors.buttonSection.remove,
                color: 'white',
                fontSize: { xs: '0.75rem', sm: '0.875rem' },  // Smaller font on mobile
                '&:hover': {
                  backgroundColor: colors.buttonSection.remove,
                  opacity: 0.9
                }
              }}
            >
              Remove
            </Button>
            <Button
              size="small"
              startIcon={<ContentCopyIcon />}
              onClick={handleDuplicate}
              sx={{ 
                backgroundColor: colors.buttonSection.duplicate,
                color: 'white',
                fontSize: { xs: '0.75rem', sm: '0.875rem' },  // Smaller font on mobile
                '&:hover': {
                  backgroundColor: colors.buttonSection.duplicate,
                  opacity: 0.9
                }
              }}
            >
              Duplicate
            </Button> */}
          </Box>

          {/* Gift List Section */}
          <Box component="div" sx={{ mt: 2 }}>
            <Box sx={{ ml: 4, mb: 4 }}>
              {generatingSuggestions && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                  <CircularProgress size={20} />
                  <Typography variant="body2" color="text.secondary">
                    Generating gift suggestions...
                  </Typography>
                </Box>
              )}
              <MemoizedGiftList 
                recipientId={recipient.id} 
                isEditing={isEditing}
                generatingSuggestions={generatingSuggestions}
                onEmptySuggestions={onEmptySuggestions}
              />
            </Box>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}, (prevProps, nextProps) => {
  // Custom comparison function for memo
  return (
    prevProps.recipient.id === nextProps.recipient.id &&
    prevProps.recipient.name === nextProps.recipient.name &&
    prevProps.recipient.description_interests === nextProps.recipient.description_interests &&
    prevProps.recipient.budget === nextProps.recipient.budget
    // Only compare fields that affect rendering
  );
});

const Recipients: React.FC = () => {
  const { user } = useAuth();
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showAddRecipientModal, setShowAddRecipientModal] = useState(false);
  const [showAddSuggestionModal, setShowAddSuggestionModal] = useState(false);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [selectedRecipient, setSelectedRecipient] = useState<Recipient | null>(null);
  const [generatingSuggestions, setGeneratingSuggestions] = useState<{ [key: number]: boolean }>({});
  const [recipientFormData, setRecipientFormData] = useState<FormData>({
    name: '',
    gender: '',
    age: '',
    occasion: '',
    budget: '50 USD',
    description_interests: '',
    relationship: 'Friend',
    liked_gift_ideas: '',
    disliked_gift_ideas: '',
    previous_successful_gifts: '',
    previous_unsuccessful_gifts: '',
  });

  const [selectedInterests, setSelectedInterests] = useState<string[]>([]);
  const [chipInputValue, setChipInputValue] = useState('');
  const [inputValue, setInputValue] = useState('');

  const [suggestionFormData, setSuggestionFormData] = useState<SuggestionFormData>({
    title: '',
    description: '',
    price: '',
    url: '',
  });

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const fetchRecipients = async () => {
    try {
      setLoading(true);
      const response = await recipientsAPI.getRecipients();
      if (response.data.success) {
        // Ensure we have an array and sort it safely
        const recipientsData = response.data.data || [];
        const sortedRecipients = [...recipientsData].sort((a, b) => {
          const indexA = typeof a.order_index === 'number' ? a.order_index : 0;
          const indexB = typeof b.order_index === 'number' ? b.order_index : 0;
          return indexA - indexB;
        });
        setRecipients(sortedRecipients);
      } else {
        setError('Failed to fetch recipients');
      }
    } catch (error) {
      setError('Error fetching recipients');
      console.error('Error fetching recipients:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRecipients();
  }, []);

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over || active.id === over.id) {
      return;
    }

    const oldIndex = recipients.findIndex(r => r.id.toString() === active.id);
    const newIndex = recipients.findIndex(r => r.id.toString() === over.id);
    
    if (oldIndex === -1 || newIndex === -1) {
      return;
    }

    try {
      // Optimistically update the UI
      setRecipients(prevRecipients => {
        const newRecipients = arrayMove(prevRecipients, oldIndex, newIndex);
        return newRecipients;
      });

      // Update the backend
      await recipientsAPI.reorderRecipients(Number(active.id), newIndex);
    } catch (error) {
      console.error('Failed to reorder recipients:', error);
      // Revert the optimistic update on error
      setRecipients(prevRecipients => {
        const revertedRecipients = arrayMove(prevRecipients, newIndex, oldIndex);
        return revertedRecipients;
      });
      setError('Failed to reorder recipients. Please try again.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!recipientFormData.name.trim()) {
      setError('Name is required');
      return;
    }

    // Auto-commit any uncommitted interest from the input
    const uncommittedInterest = chipInputValue.trim();
    let finalInterests = [...selectedInterests];
    if (uncommittedInterest) {
      finalInterests = [...selectedInterests, uncommittedInterest];
    }

    setLoading(true);
    try {
      const formattedData = {
        ...recipientFormData,
        description_interests: finalInterests.join(', '),
        budget: recipientFormData.budget,  // Keep as string for API
      };

      let response: AxiosResponse<RecipientResponse>;
      if (editingId) {
        response = await recipientsAPI.updateRecipient(editingId, formattedData);
        
        if (response.data.success) {
          setRecipients(prev => prev.map(r => 
            r.id === editingId 
              ? { 
                  ...r,
                  name: formattedData.name,
                  gender: formattedData.gender,
                  age: formattedData.age,
                  occasion: formattedData.occasion,
                  budget: formattedData.budget,  // Keep as string
                  description_interests: formattedData.description_interests,
                  relationship: formattedData.relationship,
                  liked_gift_ideas: formattedData.liked_gift_ideas,
                  disliked_gift_ideas: formattedData.disliked_gift_ideas,
                  previous_successful_gifts: formattedData.previous_successful_gifts,
                  previous_unsuccessful_gifts: formattedData.previous_unsuccessful_gifts,
                } 
              : r
          ));
          setSuccess('Gift updated successfully');
        }
      } else {
        response = await recipientsAPI.createRecipient(formattedData);
        
        if (response.data.success) {
          const newRecipient = {
            ...response.data.data,
            suggestions: []
          };
          setRecipients(prev => [...prev, newRecipient]);
          setSuccess('Gift added successfully');

          // Close modal and reset form immediately
          setShowAddRecipientModal(false);
          setRecipientFormData({
            name: '',
            gender: '',
            age: '',
            occasion: '',
            budget: '50 USD',
            description_interests: '',
            relationship: 'Friend',
            liked_gift_ideas: '',
            disliked_gift_ideas: '',
            previous_successful_gifts: '',
            previous_unsuccessful_gifts: '',
          });
          setSelectedInterests([]);
          setChipInputValue('');
          setEditingId(null);

          // Generate suggestions asynchronously
          try {
            await recipientsAPI.generateSuggestions(newRecipient.id);
            // Refresh the recipient list to get the new suggestions
            fetchRecipients();
          } catch (error) {
            console.error('Error generating suggestions:', error);
            // Don't show error to user since recipient was created successfully
          }
        }
      }

      setShowAddRecipientModal(false);
      setRecipientFormData({
        name: '',
        gender: '',
        age: '',
        occasion: '',
        budget: '50 USD',
        description_interests: '',
        relationship: 'Friend',
        liked_gift_ideas: '',
        disliked_gift_ideas: '',
        previous_successful_gifts: '',
        previous_unsuccessful_gifts: '',
      });
      setSelectedInterests([]);
      setChipInputValue('');
      setEditingId(null);
    } catch (error) {
      console.error('Error saving gift:', error);
      setError('Failed to save gift');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRecipientFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleChipInputChange = (field: keyof FormData, value: string) => {
    setRecipientFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const resetForm = useCallback(() => {
    setRecipientFormData({
      name: '',
      gender: '',
      age: '',
      occasion: '',
      budget: '50 USD',
      description_interests: '',
      relationship: 'Friend',
      liked_gift_ideas: '',
      disliked_gift_ideas: '',
      previous_successful_gifts: '',
      previous_unsuccessful_gifts: '',
    });
    setSelectedInterests([]);
    setEditingId(null);
  }, []);

  const handleEditRecipient = useCallback((recipient: Recipient) => {
    setEditingId(recipient.id);
    setRecipientFormData({
      name: recipient.name,
      gender: recipient.gender || '',
      age: recipient.age || '',
      occasion: recipient.occasion || getDefaultOccasion(),
      budget: recipient.budget || '50 USD',  // Keep as string
      description_interests: recipient.description_interests || '',
      relationship: recipient.relationship || 'Friend',
      liked_gift_ideas: recipient.liked_gift_ideas || '',
      disliked_gift_ideas: recipient.disliked_gift_ideas || '',
      previous_successful_gifts: recipient.previous_successful_gifts || '',
      previous_unsuccessful_gifts: recipient.previous_unsuccessful_gifts || '',
    });
    setSelectedInterests(recipient.description_interests ? recipient.description_interests.split(', ') : []);
    setShowAddRecipientModal(true);
  }, []);

  const handleCloseRecipientModal = useCallback(() => {
    setShowAddRecipientModal(false);
    resetForm();
    setEditingId(null);
  }, [resetForm]);

  const getDefaultOccasion = () => {
    const currentMonth = new Date().getMonth(); // 0-11, where 11 is December
    return currentMonth === 11 ? 'Christmas' : 'Birthday';
  };

  const handleAddRecipient = () => {
    setSelectedRecipient(null);
    setEditingId(null);
    setRecipientFormData({
      name: '',
      gender: '',
      age: '',
      occasion: getDefaultOccasion(),
      budget: '50 USD',
      description_interests: '',
      relationship: 'Friend',
      liked_gift_ideas: '',
      disliked_gift_ideas: '',
      previous_successful_gifts: '',
      previous_unsuccessful_gifts: '',
    });
    setSelectedInterests([]); // Clear selected interests
    setInputValue(''); // Clear the input value
    setShowAddRecipientModal(true);
  };

  const handleDelete = async (id: number) => {
    try {
      setLoading(true);
      const response = await recipientsAPI.deleteRecipient(id);
      if (response.data.success) {
        setSuccess('Recipient deleted successfully');
        await fetchRecipients();
      } else {
        setError(response.data.message || 'Failed to delete recipient');
      }
    } catch (error) {
      setError('Error deleting recipient');
      console.error('Error deleting recipient:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateWithAI = useCallback(async (recipientId: number) => {
    try {
      //console.log('Starting AI generation for recipient:', recipientId);
      
      const giftList = document.getElementById(`gift-list-${recipientId}`);
      if (giftList) {
        giftList.setAttribute('data-awaiting-ai', 'true');
        giftList.removeAttribute('data-fetch-on-mount');
        
        // Dispatch an initial event to prevent fetches
        const initEvent = new CustomEvent('refresh', {
          bubbles: true,
          detail: { type: 'ai-generate' }
        });
        giftList.dispatchEvent(initEvent);
      }
      
      const response = await recipientsAPI.generateSuggestions(recipientId);
      //console.log('AI generation response:', response);
      
      if (giftList && response.data.suggestions) {
        // Only dispatch event if we have suggestions
        const refreshEvent = new CustomEvent('refresh', {
          bubbles: true,
          detail: { 
            type: 'ai-generate',
            suggestions: response.data.suggestions || []
          }
        });
        giftList.dispatchEvent(refreshEvent);
        setSuccess('Generated new gift suggestions!');
      }
    } catch (error) {
      console.error('Error generating suggestions:', error);
      setError('Failed to generate suggestions');
      
      const giftList = document.getElementById(`gift-list-${recipientId}`);
      if (giftList) {
        giftList.removeAttribute('data-awaiting-ai');
        giftList.setAttribute('data-fetch-on-mount', 'true');
        
        // Dispatch event to re-enable fetches
        const refreshEvent = new CustomEvent('refresh', {
          bubbles: true,
          detail: { type: 'refresh-suggestions' }
        });
        giftList.dispatchEvent(refreshEvent);
      }
    }
  }, []);

  const handleAddSuggestion = useCallback(async (suggestion: SuggestionFormData) => {
    if (!selectedRecipient) return;

    try {
      const response = await recipientsAPI.addSuggestion(selectedRecipient.id, suggestion);
      if (response.data.success) {
        const giftList = document.getElementById(`gift-list-${selectedRecipient.id}`);
        if (giftList) {
          // Only dispatch event with suggestions data
          const refreshEvent = new CustomEvent('refresh', {
            bubbles: true,
            detail: { 
              type: 'suggestion-change',
              suggestions: response.data.data || []
            }
          });
          giftList.dispatchEvent(refreshEvent);
        }
        setSuccess('Suggestion added successfully');
      }
    } catch (error) {
      console.error('Error adding suggestion:', error);
      setError('Failed to add suggestion');
    } finally {
      setShowAddSuggestionModal(false);
      setSuggestionFormData({
        title: '',
        description: '',
        price: '',
        url: '',
      });
    }
  }, [selectedRecipient]);

  const handleDuplicateRecipient = (recipient: Recipient) => {
    setEditingId(null); // Ensure we're in "add" mode, not "edit" mode
    setRecipientFormData({
      name: `${recipient.name} (Copy)`,
      gender: recipient.gender || '',
      age: recipient.age || '',
      occasion: recipient.occasion || getDefaultOccasion(),
      budget: recipient.budget || '50 USD',  // Keep as string
      description_interests: recipient.description_interests || '',
      relationship: recipient.relationship || 'Friend',
      liked_gift_ideas: recipient.liked_gift_ideas || '',
      disliked_gift_ideas: recipient.disliked_gift_ideas || '',
      previous_successful_gifts: recipient.previous_successful_gifts || '',
      previous_unsuccessful_gifts: recipient.previous_unsuccessful_gifts || '',
    });
    setSelectedInterests(recipient.description_interests ? recipient.description_interests.split(', ').map(s => s.trim()) : []);
    setShowAddRecipientModal(true);
  };

  const handleSelectRecipientForSuggestion = useCallback((recipient: Recipient) => {
    setSelectedRecipient(recipient);
    setShowAddSuggestionModal(true);
    setSuggestionFormData({
      title: '',
      description: '',
      price: '',
      url: '',
    });
  }, []);

  const generateSuggestions = async (recipientId: number) => {
    if (generatingSuggestions[recipientId]) return; // Already generating

    setGeneratingSuggestions(prev => ({ ...prev, [recipientId]: true }));
    try {
      await recipientsAPI.generateSuggestions(recipientId);
      // Refresh the recipient list to get the new suggestions
      await fetchRecipients();
    } catch (error) {
      console.error('Error generating suggestions:', error);
      // Don't show error to user since this is a background operation
    } finally {
      setGeneratingSuggestions(prev => ({ ...prev, [recipientId]: false }));
    }
  };

  return (
    <div className="recipients-container">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <Box sx={{ mb: 3 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAddRecipient}
            sx={{
              backgroundColor: theme => theme.palette.mode === 'dark' 
                ? 'primary.dark' 
                : 'primary.main',
              '&:hover': {
                backgroundColor: theme => theme.palette.mode === 'dark'
                  ? 'primary.main'
                  : 'primary.dark',
              }
            }}
          >
            Add gift
          </Button>
        </Box>

        <SortableContext
          items={recipients.map(r => r.id.toString())}
          strategy={verticalListSortingStrategy}
        >
          <Grid 
            container 
            spacing={2}
            sx={{
              padding: 2,
              width: '100%',
              margin: 0
            }}
          >
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                <CircularProgress />
              </Box>
            ) : recipients && recipients.length > 0 ? (
              recipients.map((recipient) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  sx={{ 
                    minWidth: {
                      xs: '100%',  // Full width on mobile
                      sm: '100%',  // Full width on small tablets
                      md: '400px'  // Min width only on medium and up
                    },
                    width: '100%'
                  }}
                  key={recipient.id}  
                >
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 2,
                    width: '100%'
                  }}>
                    <SortableRecipient
                      recipient={recipient}
                      onEdit={handleEditRecipient}
                      onDelete={handleDelete}
                      onAddSuggestion={handleSelectRecipientForSuggestion}
                      onGenerateAI={handleGenerateWithAI}
                      onDuplicate={handleDuplicateRecipient}
                      isEditing={editingId === recipient.id}
                      generatingSuggestions={!!generatingSuggestions[recipient.id]}
                      onEmptySuggestions={() => {
                        if (!generatingSuggestions[recipient.id]) {
                          generateSuggestions(recipient.id);
                        }
                      }}
                    />
                  </Box>
                </Grid>
              ))
            ) : (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <Typography variant="h6" color="textSecondary">
                  No gifts yet. Add your first gift to get started!
                </Typography>
              </Box>
            )}
          </Grid>
        </SortableContext>
      </DndContext>

      {/* Modal for adding/editing recipients */}
      <Dialog 
        open={showAddRecipientModal} 
        onClose={handleCloseRecipientModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {editingId ? 'Edit Gift' : 'Add Gift'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="To"
                name="name"
                value={recipientFormData.name}
                onChange={handleInputChange}
                helperText="Name of the person"
                margin="normal"
                required
              />
              <Autocomplete
                fullWidth
                freeSolo
                options={genderOptions}
                value={recipientFormData.gender}
                onChange={(_, newValue) => {
                  setRecipientFormData(prev => ({
                    ...prev,
                    gender: newValue || ''
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Gender"
                    margin="normal"
                  />
                )}
              />
              <TextField
                fullWidth
                label="Age"
                name="age"
                value={recipientFormData.age}
                onChange={handleInputChange}
                margin="normal"
              />
              <Autocomplete
                fullWidth
                freeSolo
                options={occasionOptions}
                value={recipientFormData.occasion}
                onChange={(_, newValue) => {
                  setRecipientFormData(prev => ({
                    ...prev,
                    occasion: newValue || getDefaultOccasion()
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Occasion"
                    margin="normal"
                    required
                  />
                )}
              />
              <TextField
                fullWidth
                label="Budget"
                name="budget"
                value={recipientFormData.budget}
                onChange={handleInputChange}
                helperText="e.g., 50 USD, 100 EUR"
                margin="normal"
              />
              <Autocomplete
                fullWidth
                freeSolo
                options={relationshipOptions}
                value={recipientFormData.relationship}
                onChange={(_, newValue) => {
                  setRecipientFormData(prev => ({
                    ...prev,
                    relationship: newValue || 'Friend'
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Relationship"
                    margin="normal"
                  />
                )}
              />
              <Autocomplete
                multiple
                freeSolo
                options={interestSuggestions.filter(option => 
                  !selectedInterests.includes(option) &&
                  option.toLowerCase().includes(chipInputValue.toLowerCase())
                )}
                value={selectedInterests}
                onChange={(_, newValue) => {
                  setSelectedInterests(newValue);
                }}
                inputValue={chipInputValue}
                onInputChange={(_, newValue) => {
                  setChipInputValue(newValue);
                }}
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      key={option}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="About them (interests, hobbies)"
                    placeholder="Add interests and hobbies"
                    fullWidth
                  />
                )}
                filterOptions={(options, params) => {
                  const filtered = options.filter(option =>
                    option.toLowerCase().includes(params.inputValue.toLowerCase())
                  );
                  return filtered;
                }}
                componentsProps={{
                  paper: {
                    sx: { 
                      maxHeight: '200px',
                      overflowY: 'auto'
                    }
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === ',' || event.key === 'Tab' || event.key === 'Enter') {
                    event.preventDefault();
                    const value = chipInputValue.trim();
                    if (value && value !== ',') {  // Prevent adding just a comma
                      if (!selectedInterests.includes(value)) {
                        setSelectedInterests([...selectedInterests, value]);
                      }
                      setChipInputValue('');
                    }
                  }
                }}
              />
            </form>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRecipientModal} color="inherit" startIcon={<CancelIcon />}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={!recipientFormData.name}
            startIcon={editingId ? <SaveIcon /> : <AddIcon />}
          >
            {editingId ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Suggestion Modal */}
      <Dialog open={showAddSuggestionModal} onClose={() => setShowAddSuggestionModal(false)}>
        <DialogTitle>
          Add Idea
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 2 }}>
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Description"
              name="description"
              value={suggestionFormData.description}
              onChange={(e) => setSuggestionFormData({ 
                ...suggestionFormData, 
                description: e.target.value,
                title: e.target.value.split('\n')[0] // Use first line as title
              })}
              margin="normal"
            />
            <TextField
              fullWidth
              label="URL"
              name="url"
              placeholder="https://..."
              value={suggestionFormData.url}
              onChange={(e) => setSuggestionFormData({ ...suggestionFormData, url: e.target.value })}
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShowAddSuggestionModal(false);
            setSuggestionFormData({
              title: '',
              description: '',
              price: '',
              url: '',
            });
          }}>
            Cancel
          </Button>
          <Button 
            variant="contained"
            color="primary"
            onClick={() => {
              if (selectedRecipient) {
                handleAddSuggestion(suggestionFormData);
              }
            }}
            disabled={loading || !suggestionFormData.description}
          >
            Add Suggestion
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!success}
        autoHideDuration={6000}
        onClose={() => setSuccess(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setSuccess(null)} severity="success">
          {success}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Recipients;
