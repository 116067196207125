import axios, { AxiosResponse } from 'axios';
import { User } from '../types/user';

const API_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:5000' 
  : process.env.REACT_APP_API_URL || 'http://localhost:5000';
// console.log('Environment Variables:', process.env);
// console.log('API URL:', API_URL);
// console.log('Environment:', process.env.NODE_ENV);
// Create axios instance with default config
const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  // Add method override to help diagnose CORS issues
  method: 'put',  // Default method
});

// Add request interceptor
api.interceptors.request.use(
  (config) => {
    // Ensure credentials are always sent
    config.withCredentials = true;

    // Log request details in development
    if (process.env.NODE_ENV === 'development') {
      // console.log('API Request:', {
      //   url: config.url,
      //   method: config.method,
      //   withCredentials: config.withCredentials
      // });
    }
    
    return config;
  },
  (error) => {
    console.error('Request Error:', error);
    return Promise.reject(error);
  }
);

// Add a function to get the token from local storage
function getAuthToken() {
  return localStorage.getItem('authToken');
}

// Add request interceptor to include Authorization header
api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    
    // Add detailed logging for request method and headers
    // console.log('Request Method:', config.method);
    // console.log('Request URL:', config.url);
    // console.log('Request Headers:', config.headers);
    
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Define response types
interface ApiResponse<T = any> {
  success: boolean;
  data: T;
  message?: string;
}

interface LoginResponse {
  token: string;
  redirect_url?: string;
}

interface SocialLoginResponse {
  token?: string;
  redirect_url: string;
}

interface GetUserResponse {
  success: boolean;
  user: User;
}

export interface LoginResult {
  success: boolean;
  token?: string;
  error?: string;
}

// Auth API
export const authAPI = {
  login: async (email: string, password: string): Promise<LoginResult> => {
    try {
      const response = await api.post<ApiResponse<LoginResponse>>('/api/auth/login', { email, password }, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });
      return {
        success: response.data.success,
        token: response.data.data.token,
        error: response.data.message
      };
    } catch (error: any) {
      const errorMessage = (error as any).response?.data?.message || 'Login failed';
      return {
        success: false,
        error: errorMessage
      };
    }
  },
  loginGoogle: async (credential: string): Promise<LoginResult> => {
    try {
      const response = await api.post<ApiResponse<LoginResponse>>('/api/auth/loginGoogle', { credential }, {
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache'
        }
      });
      return {
        success: response.data.success,
        token: response.data.data.token,
        error: response.data.message
      };
    } catch (error: any) {
      const errorMessage = (error as any).response?.data?.message || 'Login failed';
      return {
        success: false,
        error: errorMessage
      };
    }
  },

  register: (email: string, password: string) =>
    api.post<ApiResponse<LoginResponse>>('/api/auth/register', { email, password }),
    
  logout: () =>
    api.post<ApiResponse<void>>('/api/auth/logout', {}, {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      }
    }),
  
  getUser: () =>
    api.get<GetUserResponse>('/api/auth/get-user', {
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      }
    }),
  
  checkEmail: (email: string) =>
    api.post<{ exists: boolean }>('/api/auth/check-email', { email }),

  resetPassword: (email: string) =>
    api.post<ApiResponse<void>>('/api/auth/reset-password', { email }),

  googleLogin: () =>
    api.get<ApiResponse<SocialLoginResponse>>('/api/auth/google/login'),
    
  metaLogin: () =>
    api.get<ApiResponse<SocialLoginResponse>>('/api/auth/meta/login'),
    
  updateProfile: (user: User) =>
    api.post<ApiResponse<void>>('/api/auth/update-profile', user),
};

// Recipients API
export const recipientsAPI = {
  getRecipients: () => 
    api.get<ApiResponse>('/api/recipients'),
  
  createRecipient: (data: any) => 
    api.post<ApiResponse>('/api/recipients', data),
  
  updateRecipient: (id: number, data: any) => 
    api.put<ApiResponse>(`/api/recipients/${id}`, data),
  
  deleteRecipient: (id: number) => 
    api.delete<ApiResponse>(`/api/recipients/${id}`),
  
  reorderRecipients: (recipientId: number, newIndex: number) =>
    api.put<ApiResponse>('/api/recipients/reorder', { recipientId, newIndex }),
  
  generateSuggestions: (recipientId: number) =>
    api.post<{ suggestions: string[] }>(`/api/recipients/${recipientId}/generate-suggestions`),
  
  addSuggestion: (recipientId: number, suggestion: any) =>
    api.post<ApiResponse>(`/api/recipients/${recipientId}/suggestions`, suggestion),
};

// Gifts API
export const giftsAPI = {
  getSuggestions: (recipientId: number) => 
    api.get<ApiResponse>(`/api/recipients/${recipientId}/suggestions`),
  
  addSuggestion: (recipientId: number, data: any) =>
    api.post<ApiResponse>(`/api/recipients/${recipientId}/suggestions`, data),
  
  updateSuggestion: (recipientId: number, suggestionId: number, data: any) =>
    api.put<ApiResponse>(`/api/recipients/${recipientId}/suggestions/${suggestionId}`, data),
  
  deleteSuggestion: (recipientId: number, suggestionId: number) =>
    api.delete<ApiResponse>(`/api/recipients/${recipientId}/suggestions/${suggestionId}`),
  
  reorderSuggestions: (recipientId: number, suggestionId: number, newIndex: number) =>
    api.put<ApiResponse>(`/api/recipients/${recipientId}/suggestions/reorder`, { suggestionId, newIndex }),
  
  generateSuggestions: (recipientId: number) =>
    api.post<{ suggestions: string[] }>(`/api/recipients/${recipientId}/generate-suggestions`),
};

// Feature Request API
export const featureAPI = {
  submitRequest: async (description: string) => {
    try {
      //console.log('Submitting Feature Request:', { description });
      const response = await api.post<ApiResponse>('/api/feature-request', { description });
      //console.log('Feature Request Response:', response);
      return response;
    } catch (error: any) {
      console.error('Feature Request Error:', {
        status: error.response?.status,
        data: error.response?.data,
        headers: error.response?.headers,
        message: error.message
      });
      throw error;
    }
  }
};

// Contact API
export const contactAPI = {
  submitMessage: (message: string) =>
    api.post<ApiResponse>('/api/contact', { message }),
};

// Add response interceptor
api.interceptors.response.use(
  (response) => {
    // Log response details in development
    if (process.env.NODE_ENV === 'development') {
      // console.log('API Response:', {
      //   url: response.config.url,
      //   status: response.status,
      //   data: response.data
      // });
    }
    return response;
  },
  async (error) => {
    if (error.response) {
      console.error('API Error Response:', {
        url: error.config?.url,
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
        fullError: error
      });
    } else if (error.request) {
      console.error('API No Response:', {
        url: error.config?.url,
        error: error.message,
        request: error.request
      });
    } else {
      console.error('API Request Setup Error:', error.message);
    }

    // Enhanced cookie clearing logic
    if (error.response?.status === 401) {
      document.cookie.split(';').forEach((cookie) => {
        const name = cookie.split('=')[0].trim();
        if (name.includes('session') || name.includes('piky_session')) {
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=.quickpick-backend-156727186072.us-east1.run.app`;
          document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=quickpick-backend-156727186072.us-east1.run.app`;
        }
      });

      const isAuthCheck = error.config.url?.includes('/get-user') || 
                         error.config.url?.includes('/check-email');
      if (!isAuthCheck) {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

//Might deleting this export, but leaving it here for now
export default api;
