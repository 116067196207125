import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Container,
  CircularProgress,
  Fade,
  Paper,
  IconButton,
  Alert,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';
import { authAPI, LoginResult } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';


const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 'calc(100vh - 64px)',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  marginTop: theme.spacing(8),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  width: '100%',
  maxWidth: '400px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  position: 'relative',
  transition: 'all 0.3s ease-in-out',
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 8px 32px rgba(0, 0, 0, 0.3)'
    : '0 8px 32px rgba(0, 0, 0, 0.1)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(1.5),
  textTransform: 'none',
  fontSize: '1rem',
  fontWeight: 500,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 5px 15px rgba(255, 255, 255, 0.1)'
      : '0 5px 15px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.spacing(1.5),
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-2px)',
    },
    '&.Mui-focused': {
      transform: 'translateY(-2px)',
    },
  },
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  transition: 'all 0.2s ease-in-out',
  color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[700],
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
    transform: 'scale(1.05)',
  },
}));

const NavigationSection = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

declare global {
  function gtag_report_conversion(url?: string): void;
}

// Function to set the token in local storage
function setAuthToken(token: string) {
  console.log('Setting auth token:', token);
  localStorage.setItem('authToken', token);
}

const Login: React.FC = () => {
  const theme = useTheme();
  const { login, loginGoogle } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState(() => localStorage.getItem('login_email') || '');
  const [password, setPassword] = useState('');
  const [credential, setCredential] = useState(''); //GOOGLE CREDENTIAL
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [error, setError] = useState<string | null>(() => localStorage.getItem('login_error'));
  const [success, setSuccess] = useState<string | null>(null);
  const [userExists, setUserExists] = useState<boolean | null>(() => {
    const stored = localStorage.getItem('login_user_exists');
    return stored ? JSON.parse(stored) : null;
  });
  const [showPassword, setShowPassword] = useState(() => {
    const stored = localStorage.getItem('login_show_password');
    return stored ? JSON.parse(stored) : false;
  });
  const [isPasswordError, setIsPasswordError] = useState(false);

  useEffect(() => {
    if (error) {
      localStorage.setItem('login_error', error);
    } else {
      localStorage.removeItem('login_error');
    }
    localStorage.setItem('login_email', email);
    localStorage.setItem('login_user_exists', JSON.stringify(userExists));
    localStorage.setItem('login_show_password', JSON.stringify(showPassword));
  }, [email, userExists, showPassword, error]);

  const checkEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setIsPasswordError(false);
    try {
      const response = await authAPI.checkEmail(email);
      setUserExists(response.data.exists);
      setShowPassword(true);
    } catch (error) {
      setError('Error checking email. Please try again.');
      console.error('Error checking email:', error);
    }
    setLoading(false);
    setResetLoading(false);
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Please fill in all fields');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const result: LoginResult = await login(email, password);
      if (result?.success) {
        const token = result.token;
        if (token) {
          setAuthToken(token);
          navigate('/');
        } else {
          setError('Token not found. Login failed.');
        }
      } else {
        setError(result?.error || 'Login failed');
      }
    } catch (error) {
      setError('An error occurred during login');
      console.error('Login error:', error);
    }
    setLoading(false);
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await authAPI.register(email, password);
      if (response.status === 200) {
        await login(email, password);
      } else {
        setError('Registration failed. Please try again.');
      }
    } catch (error) {
      setError('Error registering. Please try again.');
      console.error('Error registering:', error);
    }
    setLoading(false);
  };

  const handleResetPassword = async () => {
    setLoading(true);
    setResetLoading(true);
    try {
        const response = await authAPI.resetPassword(email);
        if (response.status === 200) {
            setError('Reset success: check email from no-reply@piky.ai (might be in spam)');
        } else {
            setError('Error requesting password reset');
        }
    } catch (error) {
        setError('Error requesting password reset');
        console.error('Error requesting password reset:', error);
    }
    setLoading(false);
  };

  const handleBack = () => {
    if (isPasswordError) {
      setPassword('');
      setError(null);
      setIsPasswordError(false);
      localStorage.removeItem('login_error');
    } else {
      setShowPassword(false);
      setUserExists(null);
      setPassword('');
      setError(null);
      setIsPasswordError(false);
      localStorage.removeItem('login_user_exists');
      localStorage.removeItem('login_show_password');
      localStorage.removeItem('login_error');
    }
  };

  // const handleGoogleLogin = async (email: string, password: string) => {
  //   try {
  //       // Check if user exists
  //       const userExists = await checkUserExists(email); // Implement this function based on your API

  //       if (userExists) {
  //           // Log in the user
  //           await loginUser(email, password); // Implement this function based on your API
  //       } else {
  //           // Register the new user
  //           await registerUser(email, password); // Implement this function based on your API
  //       }
  //   } catch (error) {
  //       console.error("Error during login or registration:", error);
  //       // Handle error (e.g., show a notification to the user)
  //   }
  // };

  const handleMetaLogin = async () => {
    try {
      const response = await authAPI.metaLogin();
      if (response.data.success && response.data.data.redirect_url) {
        window.location.href = response.data.data.redirect_url;
      }
    } catch (error) {
      setError('Failed to initiate Meta login');
      console.error('Meta login error:', error);
    }
  };


  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

  const handleLoginGoogle = async () => {
    if (!credential) {
      setError('Google credential not found. Login failed.');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const result: LoginResult = await loginGoogle(credential);
      if (result?.success) {
        const token = result.token;
        if (token) {
          setAuthToken(token);
          navigate('/');
        } else {
          setError('Token not found. Login failed.');
        }
      } else {
        setError(result?.error || 'Login failed');
      }
    } catch (error) {
      setError('An error occurred during login');
      console.error('Login error:', error);
    }
    setLoading(false);
  };

  const handleGoogleLoginSuccess = (credentialResponse: any) => {
    //console.log('Google credential:', credentialResponse.credential);
    setCredential(credentialResponse.credential);
    //handleLoginGoogle();
  };

  const handleGoogleLoginFailure = () => {
    //console.log('Google login failed.');
    setError('Google login failed. Please try again.');
  };

  useEffect(() => {
    const clearStorage = () => {
      if (window.location.pathname !== '/login') {
        localStorage.removeItem('login_email');
        localStorage.removeItem('login_user_exists');
        localStorage.removeItem('login_show_password');
        localStorage.removeItem('login_error');
      }
    };
    clearStorage();
  }, []);

  // Add this useEffect to trigger login when credential is set
  useEffect(() => {
    if (credential) {
      handleLoginGoogle();
    }
  }, [credential]); // This runs every time `credential` state changes

  return (
    <StyledContainer>
      <StyledPaper elevation={0}>
        <FormContainer>
          <HeaderContainer>
            <Typography 
              variant="h4" 
              component="h1" 
              gutterBottom 
              align="center" 
              sx={{ 
                fontWeight: 700,
              }}
            >
              Welcome to Piky.ai
            </Typography>
          </HeaderContainer>

          <Typography variant="body1" align="center" color="text.secondary" gutterBottom>
            {!showPassword 
              ? "Enter your email to get started"
              : userExists 
                ? "Welcome back! Please enter your password"
                : "Create your account"
            }
          </Typography>

          {(showPassword || error) && (
            <Fade in={true}>
              <NavigationSection>
                <BackButton
                  onClick={handleBack}
                  size="medium"
                  aria-label="go back"
                >
                  <ArrowBackIcon />
                </BackButton>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    ml: 1,
                    color: theme => theme.palette.mode === 'dark' ? 'grey.400' : 'grey.700',
                    flexGrow: 1,
                  }}
                >
                  {error ? "Go back" : "Change email"}
                </Typography>
              </NavigationSection>
            </Fade>
          )}

          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                width: '100%',
                backgroundColor: 'transparent',
                color: theme.palette.error.main,
                '& .MuiAlert-icon': {
                  color: theme.palette.error.main,
                  marginRight: 1,
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiAlert-message': {
                  padding: '0',
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
            >
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={!showPassword ? checkEmail : userExists ? handleLogin : handleRegister}>
            <Fade in={true}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <StyledTextField
                  fullWidth
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={showPassword}
                  required
                  autoComplete="email"
                  inputProps={{
                    autoCapitalize: 'none'
                  }}
                />

                <StyledTextField
                  fullWidth
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required={showPassword}
                  autoComplete="current-password"
                  sx={{ display: showPassword ? 'block' : 'none' }}
                />

                <StyledButton
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  sx={{ mt: 2 }}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : !showPassword ? (
                    "Continue"
                  ) : userExists ? (
                    "Sign In"
                  ) : (
                    "Create Account"
                  )}
                </StyledButton>

                {showPassword && userExists && (
                  <StyledButton
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={handleResetPassword}
                    disabled={resetLoading}  // Disable button while loading
                  >
                    {resetLoading ? (
                      <CircularProgress size={24} color="inherit" />  // Show loading spinner
                    ) : (
                      "Reset Password"
                    )}
                  </StyledButton>
                )}
              </Box>
            </Fade>
          </Box>

          {!showPassword && (
            <>
              <Divider sx={{ width: '100%', my: 2 }}>or</Divider>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
                {/* <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleLogin}
                  sx={{
                    borderColor: '#4285F4',
                    color: '#4285F4',
                    '&:hover': {
                      borderColor: '#4285F4',
                      backgroundColor: 'rgba(66, 133, 244, 0.04)',
                    },
                  }}
                >
                  Continue with Google
                </Button>
                <Button
                  variant="outlined"
                  fullWidth
                  startIcon={<FacebookIcon />}
                  onClick={handleMetaLogin}
                  sx={{
                    borderColor: '#1877F2',
                    color: '#1877F2',
                    '&:hover': {
                      borderColor: '#1877F2',
                      backgroundColor: 'rgba(24, 119, 242, 0.04)',
                    },
                  }}
                >
                  Continue with Facebook
                </Button> */}

                <GoogleOAuthProvider clientId={googleClientId}>
                  <GoogleLogin
                    onSuccess={handleGoogleLoginSuccess}
                    onError={handleGoogleLoginFailure}
                  />
                </GoogleOAuthProvider>

              </Box>
            </>
          )}
        </FormContainer>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Login;
